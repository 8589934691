import { StyleSheet, ScrollView, View } from "react-native"
import { Paragraph } from "react-native-paper"

export default function Terms() {
  return (
    <View style={styles.container}>
      <View style={styles.separator} />
      <ScrollView contentContainerStyle={styles.scrollView}>
        <Paragraph>
          By using the Yours Brightly AI application ("the App") developed by Equational
          Applications LLC, you agree to be bound by the following terms and conditions:
          {"\n"}
          {"\n"}
          1. Use of the App The App is intended for personal, non-commercial use only. You may use
          the App to chat with a virtual friend chatbot powered by OpenAI API. The App also uses
          React Native, Firebase Auth, Firestore, and Google Cloud Functions to provide you with a
          seamless user experience. You may use the App for as long as you comply with these terms
          and conditions.
          {"\n"}
          {"\n"}
          2. User Content All content generated by you in the App, including chat history, is your
          property. You grant Equational Applications LLC a non-exclusive, transferable,
          sub-licensable, royalty-free, worldwide license to use, copy, modify, create derivative
          works based on, distribute, publicly display, publicly perform, and otherwise exploit in
          any manner such User Content in all formats and distribution channels now known or
          hereafter devised (including in connection with the App and Equational Applications LLC's
          business and on third-party sites and services), without further notice to or consent from
          you, and without the requirement of payment to you or any other person or entity.
          {"\n"}
          {"\n"}
          3. Privacy Policy Your privacy is important to us. Please review our Privacy Policy to
          understand how we collect, use, and disclose your personal information.
          {"\n"}
          {"\n"}
          4. Payment and Subscription The App is available for free with limited credits. Once the
          credits are used up, a monthly subscription fee is required to continue using the App. The
          subscription fee is subject to change, and you will be notified of any changes before they
          take effect. You may cancel your subscription at any time, and cancellation will be
          effective at the end of the current subscription period.
          {"\n"}
          {"\n"}
          5. Intellectual Property The App and its contents, including but not limited to text,
          graphics, images, logos, button icons, software, and audio clips, are the property of
          Equational Applications LLC or its licensors and are protected by copyright, trademark,
          and other laws. You may not copy, modify, reproduce, upload, transmit, distribute, sell,
          or create derivative works based on the App or any part thereof without the express
          written permission of Equational Applications LLC.
          {"\n"}
          {"\n"}
          6. Disclaimer of Warranties THE APP IS PROVIDED "AS IS" AND WITHOUT WARRANTY OF ANY KIND.
          EQUATIONAL APPLICATIONS LLC DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT
          NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
          AND NON-INFRINGEMENT. EQUATIONAL APPLICATIONS LLC DOES NOT WARRANT THAT THE APP WILL MEET
          YOUR REQUIREMENTS, OR THAT THE OPERATION OF THE APP WILL BE UNINTERRUPTED OR ERROR-FREE,
          OR THAT DEFECTS IN THE APP WILL BE CORRECTED. YOU AGREE THAT YOUR USE OF THE APP IS AT
          YOUR OWN RISK.
          {"\n"}
          {"\n"}
          7. Limitation of Liability EQUATIONAL APPLICATIONS LLC SHALL NOT BE LIABLE FOR ANY
          INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING BUT NOT
          LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA, OR OTHER INTANGIBLE LOSSES
          (EVEN IF EQUATIONAL APPLICATIONS LLC HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES),
          ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE APP.
          {"\n"}
          {"\n"}
          8. Indemnification You agree to indemnify, defend, and hold Equational Applications LLC,
          its affiliates, and their respective officers, directors, employees, and agents harmless
          from and against any and all claims, liabilities, damages, losses, or expenses, including
          reasonable attorneys' fees and costs, due to or arising out of your use of the App, your
          User Content, your violation of these terms and conditions, or your violation of any
          rights of another.
          {"\n"}
          {"\n"}
          9. Governing Law These terms and conditions shall be governed by and construed in
          accordance with the laws of the State of Michigan without regard to its conflicts of law
          provisions.
          {"\n"}
          {"\n"}
          10. Dispute Resolution Any disputes arising out of or relating to these terms and
          conditions or the App shall be resolved through binding arbitration in accordance with the
          Commercial Arbitration Rules of the American Arbitration Association. The arbitration
          shall take place in Grand Rapids, Michigan, and shall be conducted in English. The
          arbitrator's decision shall be final and binding and may be entered as a judgment in any
          court of competent jurisdiction.
          {"\n"}
          {"\n"}
          11. Amendments Equational Applications LLC reserves the right to modify these terms and
          conditions at any time, in its sole discretion. Any changes will be effective immediately
          upon posting the revised terms and conditions on the App. Your continued use of the App
          following the posting of changes to these terms and conditions constitutes your acceptance
          of those changes.
          {"\n"}
          {"\n"}
          12. Termination Equational Applications LLC may terminate your use of the App at any time,
          for any reason or no reason, without notice to you. Upon termination, you must immediately
          cease all use of the App and delete all copies of the App from your device.
          {"\n"}
          {"\n"}
          13. Severability If any provision of these terms and conditions is held to be invalid or
          unenforceable, the remaining provisions shall remain in full force and effect.
          {"\n"}
          {"\n"}
          14. Entire Agreement These terms and conditions constitute the entire agreement between
          you and Equational Applications LLC with respect to the use of the App, and supersede all
          prior or contemporaneous communications and proposals, whether oral or written, between
          you and Equational Applications LLC.
          {"\n"}
          {"\n"}
          By using the App, you acknowledge that you have read, understood, and agree to be bound by
          these terms and conditions. If you do not agree to these terms and conditions, do not use
          the App.
        </Paragraph>
      </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  scrollView: {
    marginHorizontal: "10%",
    width: "80%",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
    alignItems: "center",
    justifyContent: "center",
  },
})
